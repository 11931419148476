import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Step from "./Components/Step";
import { AdsKeys } from './Api/Constants';

function App() {
  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.get('utm_source')) {
    AdsKeys.forEach(item => {
      sessionStorage.setItem(item, searchParams.get(item))
    })
  }
  return (
    <>
      <div id="main-wrapper">
        <BrowserRouter>
          <Header />
          <Routes>
            {/* Default route redirecting to /step/1 */}
            <Route path="/" element={<Navigate to="/step/1" replace />} />
            <Route path="/step/:index" element={<Step />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
